<template>
  <div class="shop_page">
    <div class="top"></div>
    <div class="top_img">汪星纪念装备</div>
    <!-- <img
      src="https://oss.prefootball.cn/uploads/20200814/772f23b3884d584433dc8cb396a01193.jpg?OSSAccessKeyId=LTAI4G1TOrM1fb6n&Expires=1616720267&Signature=3XkQ%2FuDegY9JnS%2FBeE7U528QLXY%3D"
      alt=""
    /> -->
    <img
      src="https://oss.prefootball.cn/uploads/20200814/772f23b3884d584433dc8cb396a01193.jpg"
      style="width: 80%;margin: 0 auto;display:block"
      alt="汪星纪念装备"
    />
  </div>
</template>

<script>
export default {
  name: 'integral',
  components: {},
  data() {
    return {
      active: '1-1',
    }
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.shop_page {
  width: 100%;
  margin: 100px auto 60px;
  .top {
    background-color: rgb(204, 27, 49);
    height: 12px;
  }
  .top_img {
    @include bg('../../assets/img/shop/spbntxtbg.png');
    width: 768px;
    height: 70px;
    line-height: 70px;
    margin: 0 auto;
    font-size: 34px;
    line-height: 46px;
    text-align: center;
    color: #ffffff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
  }
  .shop_bg {
    width: 100%;
    height: 615px;
    background-size: cover;
    background-position: center;
  }
}
</style>
